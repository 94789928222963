@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
@layer base {
  * {
    font-family: "Cairo", sans-serif;
  }
}

body {
  @apply bg-white;
}

/* *:lang(en) {
  font-family: "Lato" sans-serif;
}

*:lang(ar) {
  font-family: "Noto Naskh Arabic" serif;
} */

/* body {
  @apply bg-backgroundGray;
} */

a {
  @apply hover:text-current;
}

/* ----------edit for dropdown-------- */
.ui.dropdown > .dropdown.icon:before {
  @apply hidden;
}
/* ----------------------------------- */

/*--------------- edit from provider tabs -----------------------*/
.edit-For-provider-tab .ui.secondary.pointing.menu .item {
  @apply hover:text-admingreen  text-maincolor md:text-2xl text-base font-medium py-6;
}
.edit-For-provider-tab .ui.secondary.pointing.menu .active.item {
  @apply text-admingreen border-admingreen  border-b-[2px] bg-transparent;
}
.edit-For-provider-tab .ui.secondary.pointing.menu {
  @apply border-maincolor border-[1px] py-[0.5px];
}
/* ------------------------------------------------------------ */
/*------------------ edit for order tab-------------------------- */
.edit-For-order-tab .ui.secondary.menu .item {
  @apply text-maintext px-2 py-2 text-2xl;
}
.edit-For-order-tab .ui.secondary.menu .item {
  @apply hover:bg-maincolor/20 hover:text-maincolor;
}
.edit-For-order-tab .ui.secondary.menu .active.item {
  @apply bg-maincolor/10 text-maincolor;
}
/* /////////////////////////////////////////////////////////////////// */

/* ----------------------------center log in ------------------------- */
.center-abs {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* /////////////////////////////////////////////////////////////////// */

/* ----------------------------left pic ------------------------- */
.login-right-img {
  background: url("../src/assets/pic-login.svg");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* /////////////////////////////////////////////////////////////////// */

/*----------------------- edit_for_Paginatio--------------------------- */
.edit_for_Pagination > .ui.secondary.menu {
  @apply border-2 border-border  text-border shadow-none flex hover:bg-transparent  !important;
}
.edit_for_Pagination > .ui.secondary.menu .active.item {
  @apply bg-admingreen border-none rounded-md text-white text-lg !important;
}
.edit_for_Pagination > .ui.secondary.menu .item {
  @apply rounded-md  text-lg border-2 border-border  text-border hover:bg-admingreen  outline  p-0.5  mx-2 flex justify-center   !important;
}
.edit_for_Pagination > .ui.pagination.menu .item {
  @apply min-w-[2rem] !important;
}
.edit_for_Pagination > .ui.menu > .item:first-child {
  @apply rounded-md hover:bg-white !important;
}
.edit_for_Pagination > .ui.menu > .item:last-child {
  @apply rounded-md hover:bg-white !important;
}
/* /////////////////////////////////////////////////////////////////// */
.ui.labeled.input:not([class*="corner labeled"]) .label:first-child + input {
  @apply font-serif;
}
